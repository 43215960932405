import type {
  SearchProductsClustersQuery_products_ProductsResponse_filters_AttributeFilter,
} from "~/graphql/propeller/generated";
import type { FilterGroupMultiCheck, FilterGroupRange, FilterOptionMultiCheck } from "~/models/filters";
import type { SearchProductsResponse } from "~/server/api/ecom/products/search.get";

type TextFilterGroupType = FilterGroupMultiCheck;
type RangeFilterGroupType = FilterGroupRange;

export async function mapToTextFilters(
  response: SearchProductsResponse,
  searchType: string,
  translator: any,
): Promise<Array<TextFilterGroupType>> {
  const filterGroups = [] as Array<TextFilterGroupType>;

  if (!response.filters) {
    return filterGroups;
  }

  for (const filter of response.filters) {
    if (!filter.textFilter) {
      continue;
    }

    const hasSelected = filter.textFilter.some((i: any) => i.isSelected);
    const filterOptions = filter.textFilter
      .filter((i: any) => i.value !== "")
      .filter((i: any) => i.isSelected || (hasSelected ? i.countActive > 0 : i.count > 0));

    if (!filterOptions.length) {
      continue;
    }

    const filterGroup = {
      id: filter.id,
      searchId: filter.searchId,
      type: filter.type,
      title: determineTitle(filter.searchId, filter.description || "[ML]", translator),
      sortOrder: 0,
      isHiddenInUI: determineHiddenInUI(filter, searchType),
      options: [],
    } as FilterGroupMultiCheck;

    // if (categoryId) {
    //   const categoryAllowFilter = CategoryAllowFilterList[categoryId];

    //   if (categoryAllowFilter) {
    //     const allowFilter = categoryAllowFilter.filters.find((f: any) => f.searchId === filter.searchId);

    //     if (!allowFilter) {
    //       continue;
    //     }

    //     filterGroup.sortOrder = allowFilter.sortOrder;
    //   }
    // }

    for (let idx = 0; idx < filterOptions.length; idx++) {
      const filterOption = filterOptions[idx];

      filterGroup.options.push({
        label: filterOption!.value,
        value: filterOption!.value,
        count: hasSelected ? filterOption!.countActive : filterOption!.count,
        selected: filterOption!.isSelected,
      } as FilterOptionMultiCheck);
    }

    // sort by count, then by option label
    filterGroup.options.sort((a: FilterOptionMultiCheck, b: FilterOptionMultiCheck) =>
      b.count - a.count || a.label.localeCompare(b.label));

    filterGroups.push(filterGroup);
  }

  // sort by filter group title
  return filterGroups.sort((a: TextFilterGroupType, b: TextFilterGroupType) =>
    a.sortOrder - b.sortOrder || a.title.localeCompare(b.title));
}

export async function mapToRangeFilters(
  response: SearchProductsResponse,
  searchType: string,
  translator: any,
  catalogRangeFilters: Array<FilterGroupRange>,
  catalogSearchQueryRangeFilters: any,
): Promise<Array<RangeFilterGroupType>> {
  const filterGroups = [] as Array<RangeFilterGroupType>;

  if (!response.filters) {
    return filterGroups;
  }

  for (const filter of response.filters) {
    if (!filter.decimalRangeFilter && !filter.integerRangeFilter) {
      continue;
    }

    // range filters are always kept in state as every request would otherwise reset the min/max values
    let filterGroup = catalogRangeFilters.find((rf: FilterGroupRange) => rf.searchId === filter.searchId);

    if (!filterGroup) {
      filterGroup = {
        id: filter.id,
        searchId: filter.searchId,
        type: filter.type,
        title: determineTitle(filter.searchId, filter.description || "[ML]", translator),
        sortOrder: 0,
        isHiddenInUI: determineHiddenInUI(filter, searchType),
      } as FilterGroupRange;

      if (!filterGroup.min && !filterGroup.max) {
        filterGroup.min = 0; // keep this as 0, as the slider fails when min/max are identical
        filterGroup.max = Math.ceil(filter.decimalRangeFilter?.max ?? filter.integerRangeFilter?.max ?? 0);
      }
    }

    const searchQueryRangeFilter = catalogSearchQueryRangeFilters?.find((rf: any) => rf.searchId === filter.searchId);
    filterGroup.from = searchQueryRangeFilter?.from ?? filterGroup.min;
    filterGroup.to = searchQueryRangeFilter?.to ?? filterGroup.max;

    filterGroups.push(filterGroup);
  }

  // sort by filter group title
  return filterGroups.sort((a: RangeFilterGroupType, b: RangeFilterGroupType) =>
    a.sortOrder - b.sortOrder || a.title.localeCompare(b.title));
}

function determineTitle(searchId: string, defaultTitle: string, translator: any) {
  return searchId === "attr_bc_basiseenheidinhoudl"
    ? translator("product.property.volume")
    : defaultTitle;
}

function determineHiddenInUI(
  filter: SearchProductsClustersQuery_products_ProductsResponse_filters_AttributeFilter,
  searchType: string,
): boolean {
  return searchType === "domain" && filter.searchId === "attr_bc_domein";
}
